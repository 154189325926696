<template>
    <div class="p-4">
        <section class="info-tiles">
            <div class="tile is-ancestor has-text-centered">
                <div class="tile is-parent">
                    <article class="tile is-child box">
                        <p class="title">{{ nbVisits }}</p>
                        <p class="subtitle">Visites</p>
                    </article>
                    <article v-if="lastVisit" class="tile is-child box">
                        <p class="title">{{ lastVisitDateFormated }}</p>
                        <p class="subtitle">Dernière visite par {{ lastVisit.username }}</p>
                    </article>
                    <article v-if="maxVisit" class="tile is-child box">
                        <p class="title">{{ maxVisit.nb_visit }}</p>
                        <p class="subtitle">Record de visites par {{ maxVisit.username }}</p>
                    </article>
                </div>
            </div>
            <div class="tile is-ancestor has-text-centered">
                <div class="tile is-parent">
                    <article class="tile is-child box">
                        <p class="title">{{ nbGuests }}</p>
                        <p class="subtitle">Invités</p>
                    </article>
                    <article class="tile is-child box">
                        <p class="title">{{ nbConfirmedGuests }}</p>
                        <p class="subtitle">Invités confirmés</p>
                    </article>
                    <article class="tile is-child box">
                        <p class="title">{{ nbPotentialGuests }}</p>
                        <p class="subtitle">Invités potentiels</p>
                    </article>
                    <article class="tile is-child box">
                        <p class="title">{{ nbMissingGuests }}</p>
                        <p class="subtitle">Invités absents</p>
                    </article>
                    <article class="tile is-child box">
                        <p class="title">{{ nbPendingGuests }}</p>
                        <p class="subtitle">Invités en attente</p>
                    </article>
                </div>
            </div>
            <div class="tile is-ancestor has-text-centered">
                <div class="tile is-parent">
                    <article class="tile is-child box">
                        <p class="title">{{ meals.meat }}</p>
                        <p class="subtitle">Viandes rouges</p>
                    </article>
                    <article class="tile is-child box">
                        <p class="title">{{ meals.fish }}</p>
                        <p class="subtitle">Poissons blancs</p>
                    </article>
                    <article class="tile is-child box">
                        <p class="title">{{ meals.child }}</p>
                        <p class="subtitle">Menus enfants</p>
                    </article>
                </div>
            </div>
            <div class="tile is-ancestor has-text-centered">
                <div class="tile is-parent">
                    <article class="tile is-child box">
                        <p class="title">{{ nbAdults }}</p>
                        <p class="subtitle">Adultes</p>
                    </article>
                    <article class="tile is-child box">
                        <p class="title">{{ adults.confirmed }}</p>
                        <p class="subtitle">Adultes confirmés</p>
                    </article>
                    <article class="tile is-child box">
                        <p class="title">{{ nbPotentialAdults }}</p>
                        <p class="subtitle">Adultes potentiels</p>
                    </article>
                    <article class="tile is-child box">
                        <p class="title">{{ adults.missing }}</p>
                        <p class="subtitle">Adultes absents</p>
                    </article>
                    <article class="tile is-child box">
                        <p class="title">{{ adults.pending }}</p>
                        <p class="subtitle">Adultes en attente</p>
                    </article>
                </div>
            </div>
            <div class="tile is-ancestor has-text-centered">
                <div class="tile is-parent">
                    <article class="tile is-child box">
                        <p class="title">{{ nbPlusOne }}</p>
                        <p class="subtitle">+1</p>
                    </article>
                    <article class="tile is-child box">
                        <p class="title">{{ plusOne.confirmed }}</p>
                        <p class="subtitle">+1 confirmés</p>
                    </article>
                    <article class="tile is-child box">
                        <p class="title">{{ nbPotentialPlusOne }}</p>
                        <p class="subtitle">+1 potentiels</p>
                    </article>
                    <article class="tile is-child box">
                        <p class="title">{{ plusOne.missing }}</p>
                        <p class="subtitle">+1 absents</p>
                    </article>
                    <article class="tile is-child box">
                        <p class="title">{{ plusOne.pending }}</p>
                        <p class="subtitle">+1 en attente</p>
                    </article>
                </div>
            </div>
            <div class="tile is-ancestor has-text-centered">
                <div class="tile is-parent">
                    <article class="tile is-child box">
                        <p class="title">{{ nbTeens }}</p>
                        <p class="subtitle">Ados</p>
                    </article>
                    <article class="tile is-child box">
                        <p class="title">{{ teens.confirmed }}</p>
                        <p class="subtitle">Ados confirmés</p>
                    </article>
                    <article class="tile is-child box">
                        <p class="title">{{ nbPotentialTeens }}</p>
                        <p class="subtitle">Ados potentiels</p>
                    </article>
                    <article class="tile is-child box">
                        <p class="title">{{ teens.missing }}</p>
                        <p class="subtitle">Ados absents</p>
                    </article>
                    <article class="tile is-child box">
                        <p class="title">{{ teens.pending }}</p>
                        <p class="subtitle">Ados en attente</p>
                    </article>
                </div>
            </div>
            <div class="tile is-ancestor has-text-centered">
                <div class="tile is-parent">
                    <article class="tile is-child box">
                        <p class="title">{{ nbChildren }}</p>
                        <p class="subtitle">Enfants</p>
                    </article>
                    <article class="tile is-child box">
                        <p class="title">{{ children.confirmed }}</p>
                        <p class="subtitle">Enfants confirmés</p>
                    </article>
                    <article class="tile is-child box">
                        <p class="title">{{ nbPotentialChildren }}</p>
                        <p class="subtitle">Enfants potentiels</p>
                    </article>
                    <article class="tile is-child box">
                        <p class="title">{{ children.missing }}</p>
                        <p class="subtitle">Enfants absents</p>
                    </article>
                    <article class="tile is-child box">
                        <p class="title">{{ children.pending }}</p>
                        <p class="subtitle">Enfants en attente</p>
                    </article>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import dayjs from 'dayjs'
    import Api from '../../../js/api'

    export default {
        name: 'Dashboard',
        data() {
            return {
                isLoading: false,
                guests: [],
                nbVisits: 0,
                nbVisitsUser: 0,
                lastVisit: null,
                maxVisit: null,
                windowWidth: window.innerWidth,
                adults: {
                    confirmed: 0,
                    missing: 0,
                    pending: 0,
                },
                plusOne: {
                    confirmed: 0,
                    missing: 0,
                    pending: 0,
                },
                teens: {
                    confirmed: 0,
                    missing: 0,
                    pending: 0,
                },
                children: {
                    confirmed: 0,
                    missing: 0,
                    pending: 0,
                },
                meals: {
                    meat: 0,
                    fish: 0,
                    child: 0,
                },
            }
        },
        computed: {
            lastVisitDateFormated: function () {
                if (this.lastVisit) {
                    return this.lastVisit.last_visit ? dayjs(this.lastVisit.last_visit).format('DD/MM/YYYY à HH:mm') : '';
                }
                return '';
            },
            nbAdults: function () {
                return this.adults.confirmed + this.adults.missing + this.adults.pending;
            },
            nbPotentialAdults: function () {
                return this.adults.confirmed + this.adults.pending;
            },
            nbPlusOne: function () {
                return this.plusOne.confirmed + this.plusOne.missing + this.plusOne.pending;
            },
            nbPotentialPlusOne: function () {
                return this.plusOne.confirmed + this.plusOne.pending;
            },
            nbTeens: function () {
                return this.teens.confirmed + this.teens.missing + this.teens.pending;
            },
            nbPotentialTeens: function () {
                return this.teens.confirmed + this.teens.pending;
            },
            nbChildren: function () {
                return this.children.confirmed + this.children.missing + this.children.pending;
            },
            nbPotentialChildren: function () {
                return this.children.confirmed + this.children.pending;
            },
            nbGuests: function () {
                return this.nbAdults + this.nbPlusOne + this.nbTeens + this.nbChildren;
            },
            nbPotentialGuests: function () {
                return this.nbPotentialAdults + this.nbPotentialPlusOne + this.nbPotentialTeens + this.nbPotentialChildren;
            },
            nbConfirmedGuests: function () {
                return this.adults.confirmed + this.plusOne.confirmed + this.teens.confirmed + this.children.confirmed;
            },
            nbMissingGuests: function () {
                return this.adults.missing + this.plusOne.missing + this.teens.missing + this.children.missing;
            },
            nbPendingGuests: function () {
                return this.adults.pending + this.plusOne.pending + this.teens.pending + this.children.pending;
            },
        },
        mounted: function() {
            let self = this;
            Api.getAllStats().then(function (response) {
                if (response.data.status === 'OK') {
                    const data = response.data.content;
                    self.guests = data.guests;
                    self.guests.forEach(guest => {
                        if (guest.type === 'Adulte') {
                            if (guest.confirm === '1') {
                                self.adults.confirmed++;
                            } else if (guest.confirm === '0') {
                                self.adults.missing++;
                            } else {
                                self.adults.pending++;
                            }
                            if (guest.has_plus_one === '1') {
                                if (guest.confirm === '0' || guest.confirm_plus_one === '0') {
                                    self.plusOne.missing++;
                                } else if (guest.confirm_plus_one === '1') {
                                    self.plusOne.confirmed++;
                                } else {
                                    self.plusOne.pending++;
                                }
                            }
                        } else if (guest.type === 'Ado') {
                            if (guest.confirm === '1') {
                                self.teens.confirmed++;
                            } else if (guest.confirm === '0') {
                                self.teens.missing++;
                            } else {
                                self.teens.pending++;
                            }
                        } else if (guest.type === 'Enfant') {
                            if (guest.confirm === '1') {
                                self.children.confirmed++;
                            } else if (guest.confirm === '0') {
                                self.children.missing++;
                            } else {
                                self.children.pending++;
                            }
                        }
                        if (guest.id_guests_meals === '1') {
                            self.meals.meat++;
                        } else if (guest.id_guests_meals === '2') {
                            self.meals.fish++;
                        } else if (guest.id_guests_meals === '3') {
                            self.meals.child++;
                        }
                    });
                    if (data.users.length) {
                        self.nbUsersVisited = data.users.filter((user) => user.last_visit !== null).length;
                        self.lastVisit = data.users.sort(self.sortDateWithNull)[0];
                        self.maxVisit = data.users.sort((a, b) => parseInt(b.nb_visit) - parseInt(a.nb_visit))[0];
                    }
                    self.nbVisits = data.visits.length;
                    if (self.nbVisits) {
                        self.nbVisitsUser = data.visits.filter((visit) => visit.id_user !== '0').length;
                    }
                }
            });
            window.addEventListener('resize', () => {
                this.windowWidth = window.innerWidth;
            });
        },
        methods: {
            sortDateWithNull: function (a, b) {
                if (!a.last_visit && !b.last_visit) {
                    return 0;
                }
                if (!a.last_visit) {
                    return 1;
                }
                if (!b.last_visit) {
                    return -1;
                }
                return dayjs(b.last_visit).unix() - dayjs(a.last_visit).unix();
            },
        },
    }
</script>
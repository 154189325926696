<template>
    <div>
        <BaseTemplate>
            <template v-slot:content>
                <div>
                    Oups, page introuvable...
                </div>
            </template>
        </BaseTemplate>
    </div>
</template>

<script>
import BaseTemplate from '../common/Base'

export default {
    name: 'NotFound',
    components: {
        BaseTemplate,
    },
}
</script>
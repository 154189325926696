import Vue from 'vue';
import Buefy from 'buefy';
import App from './app/App.vue';
import router from './app/router';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faCircleNotch, faArrowUp, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

import 'buefy/dist/buefy.css';

library.add(faCircleNotch, faArrowUp, faExclamationCircle);
Vue.component('vue-fontawesome', FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.use(Buefy, {
    defaultIconComponent: "vue-fontawesome",
    defaultIconPack: "fas",
});

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')

<template>
    <BaseTemplate>
        <template v-slot:content>
            <div class="hero-body has-text-centered is-justify-content-center">
                <div class="login">
                    <b-field
                        :type="invalidEmail ? 'is-danger' : ''"
                        :message="errorMessage"
                    >
                        <b-input
                            size="is-medium"
                            type="email"
                            placeholder="email"
                            v-model="email"
                            :disabled="sendingNewPassword"
                             @keyup.native.enter="forgotPassword"
                        ></b-input>
                    </b-field>
                    <br />
                    <b-button type="is-info" size="is-medium" :disabled="!isFormValid || sendingNewPassword" @click="forgotPassword">
                        Demander un changement de mot de passe 
                        <b-icon
                            v-if="sendingNewPassword"
                            pack="fas"
                            icon="circle-notch"
                            custom-class="fa-spin">
                        </b-icon>
                    </b-button>
                </div>
            </div>
        </template>
    </BaseTemplate>
</template>


<script>
import BaseTemplate from '../front/common/Base'
import Api from '../../js/api'

export default {
    name: 'ForgotPassword',
    components: {
        BaseTemplate,
    },
    data: () => ({
        email: '',
        sendingNewPassword: false,
        invalidEmail: false,
        errorMessage: '',
    }),
    computed: {
        isFormValid() {
            return (this.email !== '');
        },
    },
    methods: {
        forgotPassword() {
            if (this.isFormValid) {
                let self = this;
                this.sendingNewPassword = true;
                Api.forgotEmail(this.email).then(function (response) {
                    self.sendingNewPassword = false;
                    if (response.data.status === 'OK') {
                        self.email = '';
                        self.invalidEmail = false;
                        self.errorMessage = '';
                        self.$router.push({ name: 'Login' })
                    } else {
                        self.invalidEmail = true;
                        self.errorMessage = response.data.message;
                    }
                });
            }
        },
    },
}
</script>


<style scoped>
    .input {
        background-color: rgba(255, 255, 255, 0.7);
    }
</style>

<template>
    <div class='page' :style="{'background-image': 'url('+background+')'}">
        <div class='background-overlay'
            :style="backgroundOverlay"
        ></div>
        <Navbar></Navbar>
        <section class="hero is-fullheight-with-navbar">
            <slot name="content"></slot>
        </section>
    </div>
</template>

<script>
import Navbar from './Navbar'

export default {
    name: 'BaseTemplate',
    components: {
        Navbar,
    },
    computed: {
        background: function() {
            let image = 'plage';
            if (this.$route.name === 'Invite') {
                image = 'terrasse';
            } else if (this.$route.name === 'Place') {
                image = 'camping';
            } else if (this.$route.name === 'WeddingList') {
                image = 'perou';
            } else if (this.$route.name === 'Contact') {
                image = 'famille';
            }
            return require('../../../assets/'+image+'.jpg');
        },
        backgroundOverlay: function() {
            let opacity = '00'
            if (this.$route.name === 'Home') {
                opacity = '60';
            } else if (this.$route.name === 'Login') {
                return {
                    'display': 'none',
                }
            } else if (this.$route.name === 'ForgotPassword') {
                opacity = '60';
            } else if (this.$route.name === 'RenewPassword') {
                opacity = '60';
            } else if (this.$route.name === 'Invite') {
                opacity = '90';
            } else if (this.$route.name === 'Place') {
                opacity = 'b8';
            } else if (this.$route.name === 'WeddingList') {
                opacity = '90';
            } else if (this.$route.name === 'Contact') {
                opacity = '60';
            }
            return {
                'background-color': '#ffffff' + opacity,
            };
        },
    },
}
</script>


<style scoped>
    .page {
        background-size: cover;
        background-position: center;
    }
    a {
        color: #ffffff;
    }
    a:hover {
        color: #262f64;
    }
    a.is-active {
        color: #262f64;
    }
    .button.is-info {
        border-radius: 12px;
        background-color: #262f64;
    }
    .button.is-info:hover {
        background-color: #262f64;
    }
    .button.is-info:disabled {
        background-color: #5a5a5a;
        opacity: 1;
    }
    a .button.is-info {
        border-radius: 12px;
        background-color: #262f64;
    }
    a .button.is-info:hover {
        background-color: #262f64;
    }
    a .button.is-info:disabled {
        background-color: #5a5a5a;
        opacity: 1;
    }
    .background-overlay {
        position: fixed;
        height: 100%;
        width: 100%;
    }
    section.hero {
        color: #262f64;
    }
</style>
<style>
    .title {
        font-family: 'Pacifico';
    }
    input.input {
        background-color: #ffffffa0;
    }
    select {
        background-color: #ffffffa0!important;
    }
    textarea.textarea {
        background-color: #ffffffa0;
    }
</style>


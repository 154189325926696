<template>
    <BaseTemplate>
        <template v-slot:content>
            <div class="hero-body has-text-centered is-justify-content-center">
                <div class="login">
                    <b-field
                        :type="invalidCredentials ? 'is-danger' : ''"
                    >
                        <b-input
                            size="is-medium"
                            placeholder="Email"
                            v-model="username"
                            :disabled="loggingIn"
                        ></b-input>
                    </b-field>
                    <b-field
                        :type="invalidCredentials ? 'is-danger' : ''"
                        :message="invalidCredentials ? 'La combinaison nom d\'utilisateur / mot de passe est erronée' : ''"
                    >
                        <b-input
                            size="is-medium"
                            type="password"
                            placeholder="Mot de passe"
                            v-model="password"
                            :disabled="loggingIn"
                             @keyup.native.enter="login"
                        ></b-input>
                    </b-field>
                    <b-field>
                        <router-link :to="{name: 'ForgotPassword'}" class="has-text-dark is-size-7">
                            j'ai oublié mon mot de passe
                        </router-link>
                    </b-field>
                    <SaveButton
                        label="Se connecter"
                        :formvalid="isFormValid"
                        :loading="loggingIn"
                        @submit="login"
                    ></SaveButton>
                </div>
            </div>
        </template>
    </BaseTemplate>
</template>


<script>
import BaseTemplate from '../front/common/Base'
import Api from '../../js/api'
import SaveButton from '../front/common/component/SaveButton'

export default {
    name: 'Login',
    components: {
        BaseTemplate,
        SaveButton,
    },
    data: () => ({
        username: '',
        password: '',
        loggingIn: false,
        invalidCredentials: false,
    }),
    computed: {
        isFormValid() {
            return (this.username !== '' && this.password !== '');
        },
    },
    methods: {
        login() {
            if (this.isFormValid) {
                let self = this;
                this.loggingIn = true;
                Api.login(this.username, this.password).then(function (response) {
                    self.loggingIn = false;
                    if (response.data.status === 'OK') {
                        self.username = '';
                        self.password = '';
                        self.invalidCredentials = false;
                        localStorage.token = response.data.token;
                        if (self.$route.params.routeTo) {
                            self.$router.push({name: self.$route.params.routeTo});
                        } else {
                            self.$router.push({ name: 'Home' });
                        }
                    } else {
                        self.invalidCredentials = true;
                    }
                });
            }
        },
    },
}
</script>


<style scoped>
    .input {
        background-color: rgba(255, 255, 255, 0.7);
    }
</style>

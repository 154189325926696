<template>
    <div>
        <BaseTemplate>
        <template v-slot:content>
            <div class="hero-body has-text-centered is-justify-content-center">
                <div class="login">
                    <b-field 
                        label="Modifier mon email"
                        custom-class="field-label"
                        :type="!isEmail ? 'is-danger' : ''"
                        :message="!isEmail ? 'Votre email n\'est pas conforme' : ''"
                    >
                        <b-input
                            size="is-medium"
                            placeholder="Email"
                            v-model="email"
                        ></b-input>
                    </b-field>
                    <b-field label="modifier mon mot de passe" custom-class="field-label">
                        <b-input
                            size="is-medium"
                            type="password"
                            placeholder="Ancien mot de passe"
                            v-model="oldPassword"
                        ></b-input>
                    </b-field>
                    <b-field
                        :type="weakPassword ? 'is-danger' : ''"
                        :message="weakPassword ? 'Le mot de passe doit comporter 8 caractères dont une majuscule, une minuscule et un chiffre' : ''"
                    >
                        <b-input
                            size="is-medium"
                            type="password"
                            placeholder="Nouveau mot de passe"
                            v-model="newPassword"
                        ></b-input>
                    </b-field>
                    <b-field
                        :type="notSamePasswords ? 'is-danger' : ''"
                        :message="notSamePasswords ? 'Les deux mots de passe ne sont pas identiques' : ''"
                    >
                        <b-input
                            size="is-medium"
                            type="password"
                            placeholder="Confirmation du nouveau mot de passe"
                            v-model="confirmPassword"
                        ></b-input>
                    </b-field>
                    <SaveButton
                        label="Sauvegarder"
                        :formvalid="isFormValid"
                        :loading="savingInfo"
                        @submit="saveInfos"
                    ></SaveButton>
                </div>
            </div>
        </template>
        </BaseTemplate>
    </div>
</template>

<script>
import BaseTemplate from '../common/Base'
import Api from '../../../js/api'
import SaveButton from '../common/component/SaveButton'

export default {
    name: 'Info',
    components: {
        BaseTemplate,
        SaveButton,
    },
    data: () => ({
        userEmail: '',
        email: '',
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
        savingInfo: false,
    }),
    mounted: function () {
        let self = this;
        let token = localStorage.token;
        Api.getUserByToken(token).then(function (response) {
            if (response.data.status === 'OK') {
                self.userEmail = response.data.user.email || '';
                self.email = response.data.user.email || '';
            }
        });
    },
    computed: {
        isEmail() {
            return (this.email === "" || this.email.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+$/));
        },
        emailNotChanged() {
            return this.userEmail === this.email;
        },
        emailPartValid() {
            return this.isEmail && this.userEmail !== this.email;
        },
        passwordsEmpty() {
            return (this.oldPassword === '' && this.newPassword === '' && this.confirmPassword === '');
        },
        weakPassword() {
            const weaknessRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
            return (this.newPassword !== '' && !weaknessRegex.test(this.newPassword));
        },
        notSamePasswords() {
            return (this.newPassword !== this.confirmPassword && !this.weakPassword && this.confirmPassword !== '');
        },
        passwordPartValid() {
            return this.oldPassword !== '' && this.newPassword !== '' && !this.notSamePasswords && !this.weakPassword;
        },
        isFormValid() {
            return ((this.emailPartValid && this.passwordPartValid) || (this.emailPartValid && this.passwordsEmpty) || (this.passwordPartValid && this.emailNotChanged));
        },
    },
    methods: {
        saveInfos() {
            if (this.isFormValid) {
                let self = this;
                this.savingInfo = true;
                let email = this.emailPartValid ? this.email : '';
                Api.saveUserInfos(localStorage.token, email, this.newPassword, this.oldPassword).then(function (response) {
                    self.savingInfo = false;
                    if (response.data.status === 'OK') {
                        self.userEmail = self.email;
                        self.oldPassword = '',
                        self.newPassword = '',
                        self.confirmPassword = '',
                        self.$buefy.toast.open({
                            message: 'Les infos ont été sauvegardées',
                            type: 'is-success',
                        });
                    } else if (response.data.message === 'bad password') {
                        self.$buefy.toast.open({
                            message: 'L\'ancien mot de passe fourni n\'est pas le bon',
                            type: 'is-danger',
                        });
                    }
                });
            }
        },
    },
}
</script>


<style>
    .login {
        width: 450px;
    }
    .field-label.label {
        color: #ffffff;
        text-align: center;
        font-weight: 400;
    }
</style>
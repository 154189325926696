<template>
  <div>
    <b-navbar
        :transparent="true"
        :fixed-top="true"
    >
        <template #brand>
            <b-navbar-item tag="router-link" :to="{ name: 'AdminDashboard' }" class="is-size-3 is-size-4-mobile">
                Admin
            </b-navbar-item>
        </template>
        <template #start>
            <b-navbar-dropdown label="Gérer" :collapsible="true">
                <b-navbar-item tag="router-link" :to="{ name: 'AdminUsers' }">
                    Utilisateurs
                </b-navbar-item>
                <b-navbar-item tag="router-link" :to="{ name: 'AdminGuests' }">
                    Invités
                </b-navbar-item>
            </b-navbar-dropdown>
            <b-navbar-item tag="router-link" :to="{ name: 'Home' }">
                Voir le site
            </b-navbar-item>
        </template>
        <template #end>
            <b-navbar-item tag="router-link" :to="{ name: 'Logout' }">
                Se déconnecter
            </b-navbar-item>
        </template>
    </b-navbar>
    <section class="hero is-fullheight-with-navbar">
        <router-view/>
    </section>
  </div>
</template>

<script>

export default {
    name: 'Admin',
}
</script>

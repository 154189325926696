<template>
    <div>
        <BaseTemplate>
            <template v-slot:content>
                <div class="hero-body">
                    <div class="container has-text-centered">
                        <div class="column is-6 is-offset-3">
                            <div class="card-content p-1 mb-4">
                                <h2 class="subtitle">
                                    <p>Camille Diverchy et Corentin Treton</p>
                                    <p>8 impasse des poiriers</p>
                                    <p>44220 Couëron</p>
                                </h2>
                                <h2 class="subtitle">
                                    <p>Camille</p>
                                    <p>06 87 91 97 33</p>
                                </h2>
                                <h2 class="subtitle">
                                    <p>Corentin</p>
                                    <p>06 83 54 51 80</p>
                                </h2>
                            </div>
                            <b-field>
                                <b-input
                                    size="is-medium"
                                    v-model="subject"
                                    placeholder="Objet"
                                ></b-input>
                            </b-field>
                            <b-field>
                                <b-input
                                    size="is-medium"
                                    type="textarea"
                                    v-model="message"
                                    placeholder="Laissez nous un message"
                                ></b-input>
                            </b-field>
                            <SaveButton
                                label="Envoyer mon message"
                                :formvalid="isFormValid"
                                :loading="submitingMessage"
                                @submit="submitMessage"
                            ></SaveButton>
                        </div>
                    </div>
                </div>
            </template>
        </BaseTemplate>
    </div>
</template>

<script>
import BaseTemplate from '../common/Base'
import Api from '../../../js/api'
import SaveButton from '../common/component/SaveButton'

export default {
    name: 'Contact',
    components: {
        BaseTemplate,
        SaveButton,
    },
    data: () => ({
        subject: '',
        message: '',
        submitingMessage: false,
    }),
    computed: {
        isFormValid() {
            return (this.subject.length && this.message.length);
        },
    },
    methods: {
        submitMessage() {
            this.submitingMessage = true;
            let self = this;
            Api.sendMessage(self.subject, self.message, localStorage.token).then(function (response) {
                self.submitingMessage = false;
                if (response.data.status === 'OK') {
                    self.$buefy.toast.open({
                        message: 'Le message a bien été envoyé',
                        type: 'is-success',
                    });
                    self.subject = '';
                    self.message = '';
                } else {
                    self.$buefy.toast.open({
                        message: 'Oups, une erreur est survenue, le message n\'a pas pu être envoyé',
                        type: 'is-danger',
                    });
                }
            });

        },
    },
}
</script>

<style scoped>
    .subtitle {
        color: #262f64;
    }
</style>
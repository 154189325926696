<template>
    <BaseTemplate>
        <template v-slot:content>
            <div class="hero-body has-text-centered is-justify-content-center">
                <div class="login">
                    <b-field class="has-text-white is-size-3">
                        Réinitialisation du mot de passe
                    </b-field>
                    <b-field
                        :type="weakPassword ? 'is-danger' : ''"
                        :message="weakPassword ? 'Le mot de passe doit comporter 8 caractères dont une majuscule, une minuscule et un chiffre' : ''"
                    >
                        <b-input
                            size="is-medium"
                            type="password"
                            placeholder="Mot de passe"
                            v-model="firstPassword"
                            :disabled="submitingPassword"
                        ></b-input>
                    </b-field>
                    <b-field
                        :type="notSamePasswords ? 'is-danger' : ''"
                        :message="notSamePasswords ? 'Les deux mots de passe ne sont pas identiques' : ''"
                    >
                        <b-input
                            size="is-medium"
                            type="password"
                            placeholder="Confirmation du mot de passe"
                            v-model="secondPassword"
                            :disabled="submitingPassword"
                             @keyup.native.enter="submitPasswords"
                        ></b-input>
                    </b-field>
                    <b-button type="is-info" size="is-medium" :disabled="!isFormValid || submitingPassword" @click="submitPasswords">
                        Changer mon mot de passe 
                        <b-icon
                            v-if="submitingPassword"
                            pack="fas"
                            icon="circle-notch"
                            custom-class="fa-spin">
                        </b-icon>
                    </b-button>
                </div>
            </div>
        </template>
    </BaseTemplate>
</template>


<script>
import BaseTemplate from '../front/common/Base'
import Api from '../../js/api'

export default {
    name: 'RenewPassword',
    beforeRouteEnter: (to, from, next) => {
        Api.verifyPasswordToken(to.params.passwordToken).then(function (response) {
            if (response.data.status === 'OK') {
                next();
            } else {
                next({name: 'Login'});
            }
        });
    },
    components: {
        BaseTemplate,
    },
    data: () => ({
        firstPassword: '',
        secondPassword: '',
        submitingPassword: false,
    }),
    computed: {
        weakPassword() {
            const weaknessRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
            return (this.firstPassword !== '' && !weaknessRegex.test(this.firstPassword));
        },
        notSamePasswords() {
            return (this.firstPassword !== this.secondPassword && !this.weakPassword && this.secondPassword !== '');
        },
        isFormValid() {
            return (this.firstPassword !== '' && !this.weakPassword && !this.notSamePasswords);
        },
    },
    methods: {
        submitPasswords() {
            if (this.isFormValid) {
                let self = this;
                this.submitingPassword = true;
                Api.renewPassword(this.$route.params.passwordToken, this.firstPassword).then(function (response) {
                    self.submitingPassword = false;
                    if (response.data.status === 'OK') {
                        self.firstPassword = '';
                        self.secondPassword = '';
                        localStorage.token = response.data.token;
                        self.$router.push({ name: 'Home' })
                    }
                });
            }
        },
    },
}
</script>


<style scoped>
    .input {
        background-color: rgba(255, 255, 255, 0.7);
    }
</style>

<template>
    <div>
        <BaseTemplate>
            <template v-slot:content>
                <div class="hero-body">
                    <div class="container has-text-centered">
                        <div class="column is-8 is-offset-2">
                            <div class="p-1 mb-4">
                                <p class="title pb-5">
                                    Nous nous marions le 10 septembre 2022
                                </p>
                                <div class="subtitle has-text-left">
                                    <p>Nous serons ravis de vous avoir avec nous pour cette journée particulière.</p>
                                    <p>La cérémonie est prévue sur la plage de Saint-Brevin-les-Pins (44), à 10mn à pied de la salle de réception.</p>
                                    <p>Il n'y a pas de dress code, venez comme vous avez envie.</p>
                                    <p>Et si vous avez besoin d'un dress code, pensez bord de mer et dunes.</p>
                                </div>
                            </div>
                            <div class="p-1 mb-4">
                                <div class="subtitle">
                                    <p>15h00 : Rdv à la salle pour partir à pied à la cérémonie</p>
                                    <p>15h30 : Cérémonie sur la plage</p>
                                    <p>17h30 : Apéritif sur la terrasse</p>
                                    <p>20h00 : Diner</p>
                                    <p>dimanche midi : Brunch</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </BaseTemplate>
    </div>
</template>

<script>
import BaseTemplate from '../common/Base'

export default {
    name: 'Home',
    components: {
        BaseTemplate,
    },
}
</script>

<style scoped>
    .title {
        color: #262f64;
    }
    .subtitle {
        color: #262f64;
    }
</style>
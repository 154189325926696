<template>
    <div>
        <BaseTemplate>
            <template v-slot:content>
                <div class="hero-body">
                    <div class="container has-text-centered">
                        <div class="column is-8 is-offset-2">
                            <div class="card-content p-1 mb-4">
                                <div class="p-1 mb-4">
                                    <p class="title pb-5">
                                        Où nous trouver ?
                                    </p>
                                    <div class="subtitle has-text-left">
                                        <p>Nous vous donnons rendez-vous au restaurant "Côtes et Dunes" à 15h.</p>
                                        <p>Il est situé au 1 allée du Béa à Saint-Brevin-les-Pins (44250).</p>
                                        <p>Nous savons que vous êtes nombreux à venir de loin, ça tombe bien ce lieu est aussi un camping.</p>
                                        <p>Vous pouvez (s'il reste de la place) réserver à "La Dune de Jade".</p>
                                        <p>S'il est malheureusement complet, il y a aussi le "Sunelia le Fief" juste à côté.</p>
                                        <p>Saint-Brévin étant un endroit touristique, les hébergements ne manquent pas.</p>
                                    </div>
                                    <div class="has-text-centered">
                                        <p>Itinéraires</p>
                                        <a href="https://goo.gl/maps/oPS7TqrNwfrc8SxUA">
                                            <img class="m-4" width="64px" height="64px" src="../../../assets/maps.png"/>
                                        </a>
                                        <a href="https://waze.com/ul/hgbqkwx3qe">
                                            <img class="m-4" width="64px" height="64px" src="../../../assets/waze.png"/>
                                        </a>
                                    </div>
                                </div>
                                <h2 class="subtitle">
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </BaseTemplate>
    </div>
</template>

<script>
import BaseTemplate from '../common/Base'

export default {
    name: 'Place',
    components: {
        BaseTemplate,
    },
}
</script>

<style scoped>
    .title {
        color: #262f64;
    }
    .subtitle {
        color: #262f64;
    }
</style>
<template>
    <b-button type="is-info" size="is-medium" :disabled="!formvalid || loading" @click="$emit('submit')">
        <span :style="{visibility: loading ? 'hidden' : null}">{{ label }}</span>
        <b-icon
            v-if="loading"
            pack="fas"
            icon="circle-notch"
            custom-class="fa-spin"
            :style="{position: 'absolute', left: 0, width: '100%'}"
        >
        </b-icon>
    </b-button>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: 'Sauvegarder',
        },
        formvalid: {
            type: Boolean,
            default: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },

}
</script>

<style>

</style>
<template>
    <div>
        <BaseTemplate>
            <template v-slot:content>
                <div class="hero-body">
                    <div class="container has-text-centered">
                        <div class="column is-8 is-offset-2">
                            <div class="card-content p-1 mb-4">
                                <p class="title pb-5">
                                    Il n'y a pas de liste de mariage :)
                                </p>
                                <div class="subtitle has-text-left">
                                    <p>Nous n'avons besoin de rien, mais rêvons de voyage.</p>
                                    <p>Un de nos rêves est de parcourir la route des incas au Pérou à moto.</p>
                                    <p>Si vous voulez participer à la réalisation de ce voyage, une cagnotte sera mise à disposition sur place.</p>
                                    <p>Le plus important pour nous reste de vous avoir à nos côtés pour cette journée.</p>
                                </div>
                                <div class="has-text-centered">
                                    <a href="https://lydia-app.com/collect/mariage-camille-et-corentin/fr"  target="_blank">
                                        <button
                                            class="button is-info"
                                            type="is-info"
                                            size="is-medium"
                                        >
                                            <span>Cagnotte Lydia</span>
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </BaseTemplate>
    </div>
</template>

<script>
import BaseTemplate from '../common/Base'

export default {
    name: 'WeddingList',
    components: {
        BaseTemplate,
    },
}
</script>

<style scoped>
    .title {
        color: #262f64;
    }
    .subtitle {
        color: #262f64;
    }
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Api from '../js/api'
import Admin from './admin/Admin'
import Login from './login/Login'
import Logout from './login/Logout'
import ForgotPassword from './login/ForgotPassword'
import RenewPassword from './login/RenewPassword'
import Dashboard from './admin/component/Dashboard'
import Guests from './admin/component/Guests'
import Users from './admin/component/Users'
import NotFound from './front/notFound/NotFound';
import Home from './front/home/Home';
import Place from './front/place/Place';
import Invite from './front/invite/Invite';
import WeddingList from './front/weddingList/WeddingList';
import Contact from './front/contact/Contact';
import Info from './front/info/Info';
// import SaveTheDate from './front/savethedate/SaveTheDate'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: {
      name: 'Home',
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
  },
  {
    path: '/forgotPassword',
    name: 'ForgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/renewPassword/:passwordToken',
    name: 'RenewPassword',
    component: RenewPassword,
  },
  {
    path: '/admin',
    component: Admin,
    meta: {
      requiresAdminAuth: true
    },
    children: [
      {
        name: 'AdminDashboard',
        path: '',
        component: Dashboard
      },
      {
        name: 'AdminGuests',
        path: 'guests',
        component: Guests
      },
      {
        name: 'AdminUsers',
        path: 'users',
        component: Users
      },
    ],
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/place',
    name: 'Place',
    component: Place,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/invite',
    name: 'Invite',
    component: Invite,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/weddingList',
    name: 'WeddingList',
    component: WeddingList,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/info',
    name: 'Info',
    component: Info,
    meta: {
      requiresAuth: true
    },
  },
  // {
  //   path: '/savethedate',
  //   name: 'SaveTheDate',
  //   component: SaveTheDate,
  //   props: true,
  // },
  {
    path: '/*',
    name: 'NotFound',
    component: NotFound,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'is-active',
})

router.beforeEach((to, from, next) => {
  let token = localStorage.token;
  if (to.name === "Login") {
    if (token) {
      Api.verifyToken(token).then((response) => {
        if (response.data.status === "OK") {
          if (response.data.mustChangePassword) {
            Api.mustChangePassword(token).then((response) => {
              next({name: 'RenewPassword', params: {'passwordToken': response.data.passwordToken}});
            });
          }
          if (to.params.routeTo) {
            next({name: to.params.routeTo});
          }
          next({name: 'Home'});
        }
      });
    }
  } else if (to.matched.some(record => record.meta.requiresAdminAuth)) {
    if (!token) {
      next({name: 'Login'});
    }
    Api.verifyToken(token).then((response) => {
      if (response.data.status !== "OK") {
        next({name: 'Login', params: {'routeTo': to.name}});
      } else {
        if (response.data.mustChangePassword) {
          Api.mustChangePassword(token).then((response) => {
            next({name: 'RenewPassword', params: {'passwordToken': response.data.passwordToken}});
          });
        } else if (!response.data.admin) {
          next({name: 'Home'});
        }
      }
    });
  } else if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!token) {
      next({name: 'Login', params: {'routeTo': to.name}});
    }
    Api.verifyToken(token).then((response) => {
      if (response.data.status !== "OK") {
        next({name: 'Login', params: {'routeTo': to.name}});
      } else {
        if (response.data.mustChangePassword) {
          Api.mustChangePassword(token).then((response) => {
            next({name: 'RenewPassword', params: {'passwordToken': response.data.passwordToken}});
          });
        }
      }
    });
  }
  next();
})

export default router

<template>
    <div>
        <BaseTemplate>
            <template v-slot:content>
                <div class="hero-body">
                    <div class="container has-text-centered">
                        <div class="column is-6 is-offset-3">
                            
                            <section>
                                <div
                                    v-for="guest in filteredGuests"
                                    :key="guest.id"
                                    class="mb-5"
                                >
                                    <h3 class="mb-3">{{ guest.firstname }} {{ guest.lastname }}</h3>
                                    <b-field grouped group-multiline position="is-centered">
                                        <p class="form-text">Je</p>
                                        <b-select
                                            placeholder="ne sais pas"
                                            v-model="guest.confirm"
                                        >
                                            <option value="0">
                                                ne peut pas être là
                                            </option>
                                            <option value="1">
                                                viens avec plaisir
                                            </option>
                                        </b-select>
                                        <p class="form-text" v-if="guest.confirm === '1'">et je mangerais</p>
                                        <b-select
                                            placeholder="rien du tout"
                                            v-model="guest.meal"
                                            v-if="guest.confirm === '1'"
                                        >
                                            <option v-if="['Adulte', 'Ado'].includes(guest.type)" value="1">
                                                de la viande rouge
                                            </option>
                                            <option v-if="['Adulte', 'Ado'].includes(guest.type)" value="2">
                                                du poisson blanc
                                            </option>
                                            <option v-if="['Ado', 'Enfant'].includes(guest.type)" value="3">
                                                le menu enfant
                                            </option>
                                            <option v-if="['Enfant'].includes(guest.type)" value="4">
                                                mon repas à moi
                                            </option>
                                        </b-select>
                                    </b-field>
                                    <b-field grouped group-multiline position="is-centered" v-if="guest.hasPlusOne && guest.confirm === '1'">
                                        <p class="form-text">Je viens</p>
                                        <b-select
                                            placeholder="avec quelqu'un ?"
                                            v-model="guest.confirmPlusOne"
                                        >
                                            <option value="0">
                                                avec moi-même
                                            </option>
                                            <option value="1">
                                                avec quelqu'un
                                            </option>
                                        </b-select>
                                        <p class="form-text" v-if="guest.confirmPlusOne === '1'">et qui mangera</p>
                                        <b-select
                                            placeholder="rien du tout"
                                            v-model="guest.mealPlusOne"
                                            v-if="guest.confirmPlusOne === '1'"
                                        >
                                            <option v-if="['Adulte', 'Ado'].includes(guest.type)" value="1">
                                                de la viande rouge
                                            </option>
                                            <option v-if="['Adulte', 'Ado'].includes(guest.type)" value="2">
                                                du poisson blanc
                                            </option>
                                            <option v-if="['Ado', 'Enfant'].includes(guest.type)" value="3">
                                                le menu enfant
                                            </option>
                                            <option v-if="['Enfant'].includes(guest.type)" value="4">
                                                mon repas à moi
                                            </option>
                                        </b-select>
                                    </b-field>
                                    <b-field grouped group-multiline position="is-centered" v-if="guest.hasPlusOne && guest.confirm === '1'">
                                        <p class="form-text" v-if="guest.confirmPlusOne === '1'">Mon invité</p>
                                        <b-input
                                            v-model="guest.plusOneFirstname"
                                            placeholder="Prénom"
                                            v-if="guest.confirmPlusOne === '1'"
                                        >
                                        </b-input>
                                        <b-input
                                            v-model="guest.plusOneLastname"
                                            placeholder="Nom"
                                            v-if="guest.confirmPlusOne === '1'"
                                        >
                                        </b-input>
                                    </b-field>
                                </div>
                                <SaveButton
                                    label="Sauvegarder"
                                    :formvalid="isFormValid"
                                    :loading="submitingGuests"
                                    @submit="submitGuests"
                                ></SaveButton>
                            </section>
                        </div>
                    </div>
                </div>
            </template>
        </BaseTemplate>
    </div>
</template>

<script>
import BaseTemplate from '../common/Base'
import Api from '../../../js/api'
import SaveButton from '../common/component/SaveButton'

export default {
    name: 'Invite',
    components: {
        BaseTemplate,
        SaveButton,
    },
    data: () => ({
        guests: [],
        filteredGuests: [],
        submitingGuests: false,
    }),
    mounted: function () {
        let self = this;
        let token = localStorage.token;
        Api.getGuestsByToken(token).then(function (response) {
            if (response.data.status === 'OK') {
                self.guests = response.data.content.guests;
                self.filteredGuests = self.guests.map((guest) => { 
                    return {
                        id: guest.id,
                        firstname: guest.firstname,
                        lastname: guest.lastname,
                        confirm: guest.confirm,
                        meal: guest['id_guests_meals'],
                        hasPlusOne: guest['has_plus_one'] === '1',
                        confirmPlusOne: guest['confirm_plus_one'],
                        mealPlusOne: guest['id_guests_plus_one_meals'],
                        plusOneFirstname: guest['plus_one_firstname'],
                        plusOneLastname: guest['plus_one_lastname'],
                        type: guest.type,
                    };
                });
            }
        });
    },
    computed: {
        guestComingWithoutMealCount() {
            return this.filteredGuests.filter((guest) => (guest.confirm === "1" && !guest.meal)).length;
        },
        guestPlusOneComingWithoutNameCount() {
            return this.filteredGuests.filter((guest) => (guest.confirm === "1" && guest.confirmPlusOne === "1" && (!guest.plusOneFirstname?.length || !guest.plusOneLastname?.length))).length;
        },
        guestPlusOneComingWithoutMealCount() {
            return this.filteredGuests.filter((guest) => (guest.confirm === "1" && guest.confirmPlusOne === "1" && !guest.mealPlusOne)).length;
        },
        isFormValid() {
            if (this.guestComingWithoutMealCount || this.guestPlusOneComingWithoutNameCount || this.guestPlusOneComingWithoutMealCount) {
                return false;
            }
            return true;
        },
    },
    methods: {
        submitGuests() {
            if (this.isFormValid) {
                this.guests.map((guest) => {
                    if (guest.confirm !== "1") {
                        guest.confirmPlusOne = null;
                    }
                    if (guest.confirmPlusOne !== "1") {
                        guest.plusOneFirstname = null;
                        guest.plusOneLastname = null;
                        guest.mealPlusOne = null;
                    }
                });
                let self = this;
                this.submitingGuests = true;
                Api.submitGuests(self.filteredGuests).then(function (response) {
                    self.submitingGuests = false;
                    if (response.data.status === 'OK') {
                        self.$buefy.toast.open({
                            message: 'Les invitations ont été mises à jour',
                            type: 'is-success',
                        });
                    }
                });
            }
        },
    },
}
</script>



<style scoped>
    h3 {
        font-weight: 600;
        font-size: 24px;
    }
    .form-text {
        line-height: 2.5em;
        margin-right: 0.75rem;
    }
</style>

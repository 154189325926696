import axios from 'axios'

const Api = {
    addUserVisit: async function (username) {
        return axios.post('/api/api.php', {
            action: 'add_user_visit',
            username: username,
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    login: async function (username, password) {
        return axios.post('/api/api.php', {
            action: 'login',
            username: username,
            password: password,
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    verifyToken: async function (token) {
        return await axios.post('/api/api.php', {
            action: 'verify_token',
            token: token,
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    getAllUsers: async function () {
        return axios.post('/api/api.php', {
            action: 'get_users',
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    addUser: async function (username) {
        return axios.post('/api/api.php', {
            action: 'add_user',
            username: username,
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    editUser: async function (id, username, email) {
        return axios.post('/api/api.php', {
            action: 'edit_user',
            id: id,
            username: username,
            email: email,
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    deleteUser: async function (id, delete_guests, new_id_user_for_guests) {
        return axios.post('/api/api.php', {
            action: 'delete_user',
            id: id,
            delete_guests: delete_guests,
            new_id_user_for_guests: new_id_user_for_guests,
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    getUserByUserHash: async function (userHash) {
        return axios.post('/api/api.php', {
            action: 'get_user_by_user_hash',
            userHash: userHash,
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    getUserByToken: async function (token) {
        return axios.post('/api/api.php', {
            action: 'get_user_by_token',
            token: token,
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    setUserEmail: async function (username, email) {
        return axios.post('/api/api.php', {
            action: 'set_user_email',
            username: username,
            email: email,
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    saveUserInfos: async function (token, email, password, oldPassword) {
        return axios.post('/api/api.php', {
            action: 'save_user_infos',
            token: token,
            email: email,
            password: password,
            oldPassword: oldPassword,
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    getAllGuests: async function () {
        return axios.post('/api/api.php', {
            action: 'get_all_guests',
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    addGuest: async function (form) {
        return axios.post('/api/api.php', {
            action: 'add_guest',
            form: form,
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    editGuest: async function (form) {
        return axios.post('/api/api.php', {
            action: 'edit_guest',
            form: form,
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    deleteGuest: async function (id) {
        return axios.post('/api/api.php', {
            action: 'delete_guest',
            id: id,
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    getGuestsOptions: async function () {
        return axios.post('/api/api.php', {
            action: 'get_guests_options',
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    getGuestsByToken: async function (token) {
        return axios.post('/api/api.php', {
            action: 'get_guests_by_token',
            token: token,
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    submitGuests: async function (guests) {
        return axios.post('/api/api.php', {
            action: 'submit_guests',
            guests: guests,
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    getAllStats: async function () {
        return axios.post('/api/api.php', {
            action: 'get_all_stats',
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    forgotEmail: async function (email) {
        return axios.post('/api/api.php', {
            action: 'forgot_email',
            email: email,
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    renewPassword: async function (token, password) {
        return axios.post('/api/api.php', {
            action: 'renew_password',
            token: token,
            password: password,
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    mustChangePassword: async function (token) {
        return axios.post('/api/api.php', {
            action: 'must_change_password',
            token: token,
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    verifyPasswordToken: async function (passwordToken) {
        return axios.post('/api/api.php', {
            action: 'verify_password_token',
            passwordToken: passwordToken,
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    welcomeEmails: async function (users) {
        return axios.post('/api/api.php', {
            action: 'welcome_emails',
            users: users,
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    remindEmails: async function (users) {
        return axios.post('/api/api.php', {
            action: 'remind_emails',
            users: users,
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    weddingListEmails: async function (users) {
        return axios.post('/api/api.php', {
            action: 'wedding_list_emails',
            users: users,
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    urlReminderEmails: async function (users) {
        return axios.post('/api/api.php', {
            action: 'url_reminder_emails',
            users: users,
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    ceremonyReminderEmails: async function (users) {
        return axios.post('/api/api.php', {
            action: 'ceremony_reminder_emails',
            users: users,
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    thankYouEmails: async function (users) {
        return axios.post('/api/api.php', {
            action: 'thank_you_emails',
            users: users,
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    sendMessage: async function (subject, message, token) {
        return axios.post('/api/api.php', {
            action: 'send_message',
            subject: subject,
            message: message,
            token: token,
        })
        .catch(function (error) {
            console.log(error);
        });
    },
}

export default Api
<template>
    <div class="p-4">
        <b-field>
            <p class="control">
                <b-button type="is-info" label="Ajouter un invité" @click="openGuestModal('add')" />
            </p>
        </b-field>

        <b-table :data="guests" :columns="columns" :loading="isLoading" @click="clickedRow($event)"></b-table>

        <b-modal v-model="isGuestModalActive" :on-cancel="closeModal">
            <form action="">
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">{{ isGuestModalEditing ? 'Editer' : 'Ajouter'}} un invité</p>
                        <button
                            type="button"
                            class="delete"
                            @click="closeModal"/>
                    </header>
                    <section class="modal-card-body">
                        <b-field grouped>
                            <b-input
                                v-model="form.lastname"
                                placeholder="Nom"
                                required
                            >
                            </b-input>
                            <b-input
                                v-model="form.firstname"
                                placeholder="Prénom"
                                required
                            >
                            </b-input>
                        </b-field>

                        <b-field>
                            <b-select
                                v-model="form.user"
                                placeholder="Choisir un compte utilisateur"
                                required
                            >
                                <option
                                    v-for="user in options.users"
                                    :key="user.id"
                                    :value="user.id"
                                >
                                    {{ user.username }}
                                </option>
                            </b-select>
                        </b-field>

                        <b-field grouped>
                            <b-select
                                v-model="form.category"
                                placeholder="Choisir une catégorie"
                                required
                                expanded
                            >
                                <option
                                    v-for="category in options.categories"
                                    :key="category.id"
                                    :value="category.id"
                                >
                                    {{ category.category }}
                                </option>
                            </b-select>
                            <b-select
                                v-model="form.host"
                                placeholder="Choisir un hôte"
                                required
                                expanded
                            >
                                <option
                                    v-for="host in options.hosts"
                                    :key="host.id"
                                    :value="host.id"
                                >
                                    {{ host.host }}
                                </option>
                            </b-select>
                            <b-select
                                v-model="form.type"
                                placeholder="Choisir un type"
                                required
                                expanded
                            >
                                <option
                                    v-for="type in options.types"
                                    :key="type.id"
                                    :value="type.id"
                                >
                                    {{ type.type }}
                                </option>
                            </b-select>
                        </b-field>

                        <b-field>
                            <b-select
                                v-model="form.meal"
                                placeholder="Repas"
                                required
                                expanded
                            >
                                <option
                                    v-for="meal in options.meals"
                                    :key="meal.id"
                                    :value="meal.id"
                                >
                                    {{ meal.meal }}
                                </option>
                            </b-select>
                        </b-field>

                        <b-field>
                            <b-switch
                                v-model="form.hasPlusOne"
                                :true-value="1"
                                :false-value="0"
                                type="is-success"
                                class="has-text-dark"
                            >
                                Droit à un +1
                            </b-switch>
                        </b-field>

                        <b-field>
                            <b-switch
                                v-model="form.confirm"
                                :true-value="1"
                                :false-value="0"
                                type="is-success"
                                class="has-text-dark"
                            >
                                Présent
                            </b-switch>
                        </b-field>

                        <b-field grouped v-if="form.hasPlusOne">
                            <b-switch
                                v-model="form.confirmPlusOne"
                                :true-value="1"
                                :false-value="0"
                                type="is-success"
                                class="has-text-dark"
                            >
                                +1 présent
                            </b-switch>
                            <b-input
                                v-if="form.confirmPlusOne"
                                v-model="form.plusOneFirstname"
                                placeholder="Prénom du +1"
                            >
                            </b-input>
                            <b-input
                                v-if="form.confirmPlusOne"
                                v-model="form.plusOneLastname"
                                placeholder="Nom du +1"
                            >
                            </b-input>
                            <b-select
                                v-if="form.confirmPlusOne"
                                v-model="form.mealPlusOne"
                                placeholder="Repas"
                                required
                                expanded
                            >
                                <option
                                    v-for="meal in options.meals"
                                    :key="meal.id"
                                    :value="meal.id"
                                >
                                    {{ meal.meal }}
                                </option>
                            </b-select>
                        </b-field>
                    </section>
                    <footer class="modal-card-foot">
                        <b-button
                            label="Annuler"
                            @click="closeModal" />
                        <b-button
                            label="Ajouter"
                            :disabled="!isFormValid || isLoading"
                            @click="addGuest"
                            type="is-info"
                            v-if="!isGuestModalEditing"
                        >
                            <b-icon
                                v-if="isLoading"
                                pack="fas"
                                icon="circle-notch"
                                custom-class="fa-spin">
                            </b-icon>
                        </b-button>
                        <b-button
                            label="Modifier"
                            :disabled="!isFormValid || isLoading"
                            @click="editGuest"
                            type="is-info"
                            v-if="isGuestModalEditing"
                        >
                            <b-icon
                                v-if="isLoading"
                                pack="fas"
                                icon="circle-notch"
                                custom-class="fa-spin">
                            </b-icon>
                        </b-button>
                        <b-button
                            label="Supprimer"
                            :disabled="!isFormValid || isLoading"
                            @click="deleteGuest"
                            type="is-danger"
                            v-if="isGuestModalEditing"
                        >
                            <b-icon
                                v-if="isLoading"
                                pack="fas"
                                icon="circle-notch"
                                custom-class="fa-spin">
                            </b-icon>
                        </b-button>
                    </footer>
                </div>
            </form>
        </b-modal>
    </div>
</template>

<script>
    import Api from '../../../js/api'

    export default {
        name: 'Guests',
        data() {
            return {
                guests: [],
                isGuestModalActive: false,
                isGuestModalEditing: false,
                isLoading: false,
                form: {
                    firstname: null,
                    lastname: null,
                    user: null,
                    hasPlusOne: 0,
                    host: null,
                    category: null,
                    type: null,
                    id: null,
                    confirm: null,
                    meal: null,
                    confirmPlusOne: null,
                    plusOneMeal: null,
                    plusOneFirstname: null,
                    plusOneLastname: null,
                },
                options: {
                    hosts: [],
                    categories: [],
                    types: [],
                    meals: [],
                    users: [],
                },
                columns: [
                    {
                        field: 'firstname',
                        label: 'Prenom',
                        searchable: true,
                        sortable: true,
                    },
                    {
                        field: 'lastname',
                        label: 'Nom',
                        searchable: true,
                        sortable: true,
                    },
                    {
                        field: 'confirm_label',
                        label: 'Present',
                        searchable: true,
                        sortable: true,
                    },
                    {
                        field: 'meal',
                        label: 'Repas',
                        searchable: true,
                        sortable: true,
                    },
                    {
                        field: 'has_plus_one_label',
                        label: 'Droit a un +1',
                        searchable: true,
                        sortable: true,
                    },
                    {
                        field: 'confirm_plus_one_label',
                        label: '+1 present',
                        searchable: true,
                        sortable: true,
                    },
                    {
                        field: 'plus_one_name',
                        label: 'Nom +1',
                        searchable: true,
                        sortable: true,
                    },
                    {
                        field: 'meal_plus_one',
                        label: 'Repas +1',
                        searchable: true,
                        sortable: true,
                    },
                    {
                        field: 'category',
                        label: 'Categorie',
                        searchable: true,
                        sortable: true,
                    },
                    {
                        field: 'host',
                        label: 'Hote',
                        searchable: true,
                        sortable: true,
                    },
                    {
                        field: 'type',
                        label: 'Type',
                        searchable: true,
                        sortable: true,
                    },
                    {
                        field: 'username',
                        label: 'Compte',
                        searchable: true,
                        sortable: true,
                    },
                ],
            }
        },
        computed: {
            isFormValid: function() {
                return (this.form.firstname && this.form.lastname && this.form.user && (this.form.hasPlusOne === 0 || this.form.hasPlusOne === 1) && this.form.host && this.form.category && this.form.type);
            },
        },
        watch: {
            'form.hasPlusOne': function(newValue) {
                if (!newValue) {
                    this.form.confirmPlusOne = null;
                }
            },
            'form.confirm': function(newValue) {
                if (!newValue) {
                    this.form.confirmPlusOne = null;
                }
            },
            'form.confirmPlusOne': function(newValue) {
                if (newValue) {
                    this.form.confirm = 1;
                }
            },
        },
        mounted: function() {
            let self = this;
            this.loadGuests();
            Api.getGuestsOptions().then((response) => {
                if (response.data.status === "OK") {
                    self.options = response.data.content;
                }
            });
        },
        methods: {
            clickedRow: function(row) {
                this.form.firstname = row.firstname;
                this.form.lastname = row.lastname;
                this.form.user = row['id_user'];
                this.form.hasPlusOne = parseInt(row['has_plus_one']);
                this.form.host = row['id_guests_hosts'];
                this.form.category = row['id_guests_categories'];
                this.form.type = row['id_guests_types'];
                this.form.id = row.id;
                this.form.confirm = parseInt(row['confirm']);
                this.form.meal = row['id_guests_meals'];
                this.form.confirmPlusOne = parseInt(row['confirm_plus_one']);
                this.form.plusOneFirstname = row['plus_one_firstname'];
                this.form.plusOneLastname = row['plus_one_lastname'];
                this.form.mealPlusOne = row['id_guests_plus_one_meals'];

                this.openGuestModal('edit');
            },
            openGuestModal: function (mode) {
                if (this.isGuestModalActive === false) {
                    this.isGuestModalEditing = false;
                    if (mode === 'edit') {
                        this.isGuestModalEditing = true;
                    }
                    this.isGuestModalActive = true;
                }
            },
            closeModal: function () {
                Object.keys(this.form).forEach(key => this.form[key] = null);
                this.form.hasPlusOne = 0;
                this.isGuestModalActive = false;
            },
            loadGuests: function () {
                let self = this;
                this.isLoading = true;
                Api.getAllGuests().then(function (response) {
                    if (response.data.status === 'OK') {
                        self.guests = response.data.content;
                    }
                    self.isLoading = false;
                });
            },
            addGuest: function () {
                if (this.isFormValid) {
                    let self = this;
                    if (this.form.confirmPlusOne === null) {
                        this.form.plusOneFirstname = null;
                        this.form.plusOneLastname = null;
                        this.form.mealPlusOne = null;
                    }
                    Api.addGuest(self.form).then(() => {
                        self.closeModal();
                        self.loadGuests();
                    });
                }
            },
            editGuest: function () {
                if (this.isFormValid) {
                    let self = this;
                    if (this.form.confirmPlusOne === null) {
                        this.form.plusOneFirstname = null;
                        this.form.plusOneLastname = null;
                        this.form.mealPlusOne = null;
                    }
                    Api.editGuest(self.form).then(() => {
                        self.closeModal();
                        self.loadGuests();
                    });
                }
            },
            deleteGuest: function () {
                if (this.form.id) {
                    this.$buefy.dialog.confirm({
                        message: 'Etes-vous sur de vouloir supprimer cet invité ?',
                        type: 'is-danger',
                        onConfirm: () => {
                            let self = this;
                            Api.deleteGuest(self.form.id).then(() => {
                                self.closeModal();
                                self.loadGuests();
                            });
                        }
                    });
                }
            },
        },
    }
</script>
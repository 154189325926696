<template>
    <div class="p-4">
        <b-field>
            <p class="control">
                <b-button type="is-info" label="Ajouter un utilisateur" @click="openUserModal('add')" />
            </p>
        </b-field>
        <b-field v-if="checkedRows.length">
            <p class="control">
                <b-button type="is-info" label="Envoyer mail de bienvenue" @click="openWelcomeEmailModal()" />
            </p>
        </b-field>
        <b-field v-if="checkedRows.length">
            <p class="control">
                <b-button type="is-info" label="Envoyer mail de rappel" @click="openRemindEmailModal()" />
            </p>
        </b-field>
        <b-field v-if="checkedRows.length">
            <p class="control">
                <b-button type="is-info" label="Envoyer mail de liste de mariage" @click="openWeddingListEmailModal()" />
            </p>
        </b-field>
        <b-field v-if="checkedRows.length">
            <p class="control">
                <b-button type="is-info" label="Envoyer mail de rappel de l'adresse" @click="openUrlReminderEmailModal()" />
            </p>
        </b-field>
        <b-field v-if="checkedRows.length">
            <p class="control">
                <b-button type="is-info" label="Envoyer mail de rappel de la cérémonie" @click="openCeremonyReminderEmailModal()" />
            </p>
        </b-field>
        <b-field v-if="checkedRows.length">
            <p class="control">
                <b-button type="is-info" label="Envoyer mail de remerciement" @click="openThankYouEmailModal()" />
            </p>
        </b-field>

        <b-table 
            :data="users"
            :columns="columns"
            :loading="isLoading"
            checkable
            :checked-rows.sync="checkedRows"
            @click="clickedRow($event)"
        ></b-table>

        <b-modal v-model="isUserModalActive" :on-cancel="closeModal">
            <form action="">
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">{{ isUserModalEditing ? 'Editer' : 'Ajouter'}} un utilisateur</p>
                        <button
                            type="button"
                            class="delete"
                            @click="closeModal"/>
                    </header>
                    <section class="modal-card-body">
                        <b-field>
                            <b-input
                                v-model="form.username"
                                placeholder="Nom d'utilisateur"
                                required
                            >
                            </b-input>
                        </b-field>
                        <b-field v-if="isUserModalEditing">
                            <b-input
                                v-model="form.email"
                                placeholder="Email"
                            >
                            </b-input>
                        </b-field>
                    </section>
                    <footer class="modal-card-foot">
                        <b-button
                            label="Annuler"
                            @click="closeModal" />
                        <b-button
                            label="Ajouter"
                            :disabled="!isFormValid || isLoading"
                            @click="addUser"
                            type="is-info"
                            v-if="!isUserModalEditing"
                        >
                            <b-icon
                                v-if="isLoading"
                                pack="fas"
                                icon="circle-notch"
                                custom-class="fa-spin">
                            </b-icon>
                        </b-button>
                        <b-button
                            label="Modifier"
                            :disabled="!isFormValid || isLoading"
                            @click="editUser"
                            type="is-info"
                            v-if="isUserModalEditing"
                        >
                            <b-icon
                                v-if="isLoading"
                                pack="fas"
                                icon="circle-notch"
                                custom-class="fa-spin">
                            </b-icon>
                        </b-button>
                        <b-button
                            label="Supprimer"
                            :disabled="!isFormValid || isLoading"
                            @click="openDeleteUserModal"
                            type="is-danger"
                            v-if="isUserModalEditing"
                        >
                            <b-icon
                                v-if="isLoading"
                                pack="fas"
                                icon="circle-notch"
                                custom-class="fa-spin">
                            </b-icon>
                        </b-button>
                    </footer>
                </div>
            </form>
        </b-modal>

        <b-modal v-model="isDeleteUserModalActive" :on-cancel="closeModal">
            <form action="">
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Supprimer un utilisateur</p>
                        <button
                            type="button"
                            class="delete"
                            @click="closeModal"/>
                    </header>
                    <section class="modal-card-body">
                        <b-field>
                            <b-switch
                                v-model="form.delete"
                                type="is-danger"
                            >
                                Supprimer les invités liés à cet utilisateur
                            </b-switch>
                        </b-field>
                        <b-field  v-if="!form.delete">
                            <b-select
                                placeholder="Transferer les invités à un autre utilisateur"
                                v-model="form.newUser"
                                required
                            >
                                <option
                                    v-for="user in otherUsers"
                                    :value="user.id"
                                    :key="user.id">
                                    {{ user.username }}
                                </option>
                            </b-select>
                        </b-field>
                    </section>
                    <footer class="modal-card-foot">
                        <b-button
                            label="Annuler"
                            @click="closeModal" />
                        <b-button
                            label="Supprimer"
                            :disabled="!isFormValid || isLoading"
                            @click="deleteUser"
                            type="is-danger"
                        >
                            <b-icon
                                v-if="isLoading"
                                pack="fas"
                                icon="circle-notch"
                                custom-class="fa-spin">
                            </b-icon>
                        </b-button>
                    </footer>
                </div>
            </form>
        </b-modal>

        <b-modal v-model="isWelcomeEmailModalActive" :on-cancel="closeModal">
            <form action="">
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Envoyer un mail de bienvenue aux utilisateurs</p>
                        <button
                            type="button"
                            class="delete"
                            @click="closeModal"/>
                    </header>
                    <section class="modal-card-body">
                        <b-field class="has-text-black">
                            Attention, vous allez envoyer {{checkedRows.length}} mails de bienvenue.
                            Ces comptes auront leurs mots de passe réinitialisés.
                        </b-field>
                    </section>
                    <footer class="modal-card-foot">
                        <b-button
                            label="Annuler"
                            @click="closeModal" />

                        <SaveButton
                            label="Envoyer les mails"
                            :loading="isSendingEmail"
                            @submit="sendWelcomeEmails"
                        ></SaveButton>
                    </footer>
                </div>
            </form>
        </b-modal>

        <b-modal v-model="isRemindEmailModalActive" :on-cancel="closeModal">
            <form action="">
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Envoyer un mail de rappel aux utilisateurs</p>
                        <button
                            type="button"
                            class="delete"
                            @click="closeModal"/>
                    </header>
                    <section class="modal-card-body">
                        <b-field class="has-text-black">
                            Attention, vous allez envoyer {{checkedRows.length}} mails de rappel.
                        </b-field>
                    </section>
                    <footer class="modal-card-foot">
                        <b-button
                            label="Annuler"
                            @click="closeModal" />

                        <SaveButton
                            label="Envoyer les mails"
                            :loading="isSendingEmail"
                            @submit="sendRemindEmails"
                        ></SaveButton>
                    </footer>
                </div>
            </form>
        </b-modal>

        <b-modal v-model="isWeddingListEmailModalActive" :on-cancel="closeModal">
            <form action="">
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Envoyer un mail pour la liste de mariage aux utilisateurs</p>
                        <button
                            type="button"
                            class="delete"
                            @click="closeModal"/>
                    </header>
                    <section class="modal-card-body">
                        <b-field class="has-text-black">
                            Attention, vous allez envoyer {{checkedRows.length}} mails pour la liste de mariage.
                        </b-field>
                    </section>
                    <footer class="modal-card-foot">
                        <b-button
                            label="Annuler"
                            @click="closeModal" />

                        <SaveButton
                            label="Envoyer les mails"
                            :loading="isSendingEmail"
                            @submit="sendWeddingListEmails"
                        ></SaveButton>
                    </footer>
                </div>
            </form>
        </b-modal>

        <b-modal v-model="isUrlReminderEmailModalActive" :on-cancel="closeModal">
            <form action="">
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Envoyer un mail pour rappeler l'url du site</p>
                        <button
                            type="button"
                            class="delete"
                            @click="closeModal"/>
                    </header>
                    <section class="modal-card-body">
                        <b-field class="has-text-black">
                            Attention, vous allez envoyer {{checkedRows.length}} mails pour le rappel de l'adresse du site.
                        </b-field>
                    </section>
                    <footer class="modal-card-foot">
                        <b-button
                            label="Annuler"
                            @click="closeModal" />

                        <SaveButton
                            label="Envoyer les mails"
                            :loading="isSendingEmail"
                            @submit="sendUrlReminderEmails"
                        ></SaveButton>
                    </footer>
                </div>
            </form>
        </b-modal>

        <b-modal v-model="isCeremonyReminderEmailModalActive" :on-cancel="closeModal">
            <form action="">
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Envoyer un mail pour rappeler de la cérémonie</p>
                        <button
                            type="button"
                            class="delete"
                            @click="closeModal"/>
                    </header>
                    <section class="modal-card-body">
                        <b-field class="has-text-black">
                            Attention, vous allez envoyer {{checkedRows.length}} mails pour le rappel de la cérémonie.
                        </b-field>
                    </section>
                    <footer class="modal-card-foot">
                        <b-button
                            label="Annuler"
                            @click="closeModal" />

                        <SaveButton
                            label="Envoyer les mails"
                            :loading="isSendingEmail"
                            @submit="sendCeremonyReminderEmails"
                        ></SaveButton>
                    </footer>
                </div>
            </form>
        </b-modal>

        <b-modal v-model="isThankYouEmailModalActive" :on-cancel="closeModal">
            <form action="">
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Envoyer un mail pour remercier</p>
                        <button
                            type="button"
                            class="delete"
                            @click="closeModal"/>
                    </header>
                    <section class="modal-card-body">
                        <b-field class="has-text-black">
                            Attention, vous allez envoyer {{checkedRows.length}} mails de remerciement.
                        </b-field>
                    </section>
                    <footer class="modal-card-foot">
                        <b-button
                            label="Annuler"
                            @click="closeModal" />

                        <SaveButton
                            label="Envoyer les mails"
                            :loading="isSendingEmail"
                            @submit="sendThankYouEmails"
                        ></SaveButton>
                    </footer>
                </div>
            </form>
        </b-modal>
    </div>
</template>

<script>
import Api from '../../../js/api'
import SaveButton from '../../front/common/component/SaveButton'

export default {
    name: 'Users',
    components: {
        SaveButton,
    },
    data: function () {
        return {
            users: [],
            isUserModalActive: false,
            isUserModalEditing: false,
            isDeleteUserModalActive: false,
            isWelcomeEmailModalActive: false,
            isRemindEmailModalActive: false,
            isWeddingListEmailModalActive: false,
            isUrlReminderEmailModalActive: false,
            isCeremonyReminderEmailModalActive: false,
            isThankYouEmailModalActive: false,
            isLoading: false,
            isSendingEmail: false,
            checkedRows: [],
            form: {
                username: null,
                email: null,
                id: null,
                delete: null,
                newUser: null,
            },
            columns: [
                {
                    field: 'username',
                    label: 'Nom',
                    searchable: true,
                    sortable: true,
                },
                {
                    field: 'inviteUrl',
                    label: 'Invitation',
                    searchable: true,
                },
                {
                    field: 'email',
                    label: 'Email',
                    searchable: true,
                    sortable: true,
                },
                {
                    field: 'isInit',
                    label: 'Invité par mail',
                    searchable: true,
                    sortable: true,
                },
                {
                    field: 'nb_guests',
                    label: 'Nombre d\'invités',
                    searchable: true,
                    sortable: true,
                },
                {
                    field: 'hasPendingInvite',
                    label: 'Invitations en attente',
                    searchable: true,
                    sortable: true,
                },
                {
                    field: 'last_visit',
                    label: 'Derniere visite',
                    sortable: true,
                },
                {
                    field: 'nb_visit',
                    label: 'Nombre de visite',
                    sortable: true,
                    numeric: true,
                },
                {
                    field: 'created_date',
                    label: 'Date de creation',
                    sortable: true,
                    centered: true,
                },
            ],
        };
    },
    mounted: function () {
        this.loadUsers();
    },
    computed: {
        isFormValid: function () {
            if (this.isDeleteUserModalActive) {
                return (!this.isLoading && this.form.delete || (!this.form.delete && this.form.newUser));
            } else {
                return (!this.isLoading && this.form.username?.length >= 3 && this.form.username.match(/^[a-z]+$/) && (!this.form.email || this.form.email.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)));
            }
        },
        otherUsers: function () {
            if (this.form.id) {
                return this.users.filter(user => user.id !== this.form.id);
            }
            return this.users;
        },
    },
    methods: {
        loadUsers: function () {
            let self = this;
            this.isLoading = true;
            Api.getAllUsers().then(function (response) {
                if (response.data.status === 'OK') {
                    self.users = response.data.content;
                    self.users.map((user) => {
                        user.inviteUrl = 'https://camilleetcorentin.fr/' + user.user_hash;
                        user.isInit = user['is_init'] === '1' ? 'Oui' : 'Non';
                        user.hasPendingInvite = user['nb_unconfirmed_guests'] ? 'Oui' : 'Non';
                    });
                }
                self.isLoading = false;
            });
        },
        clickedRow: function(row) {
            this.form.username = row.username;
            this.form.email = row.email;
            this.form.id = row.id;

            this.openUserModal('edit');
        },
        openUserModal: function (mode) {
            if (this.isUserModalActive === false) {
                this.isUserModalEditing = false;
                if (mode === 'edit') {
                    this.isUserModalEditing = true;
                }
                this.isUserModalActive = true;
            }
        },
        openDeleteUserModal: function () {
            if (this.isDeleteUserModalActive === false) {
                this.form.delete = null;
                this.form.newUser = null;
                this.isDeleteUserModalActive = true;
            }
        },
        openWelcomeEmailModal: function () {
            if (this.isWelcomeEmailModalActive === false) {
                this.isWelcomeEmailModalActive = true;
            }
        },
        openRemindEmailModal: function () {
            if (this.isRemindEmailModalActive === false) {
                this.isRemindEmailModalActive = true;
            }
        },
        openWeddingListEmailModal: function () {
            if (this.isWeddingListEmailModalActive === false) {
                this.isWeddingListEmailModalActive = true;
            }
        },
        openUrlReminderEmailModal: function () {
            if (this.isUrlReminderEmailModalActive === false) {
                this.isUrlReminderEmailModalActive = true;
            }
        },
        openCeremonyReminderEmailModal: function () {
            if (this.isCeremonyReminderEmailModalActive === false) {
                this.isCeremonyReminderEmailModalActive = true;
            }
        },
        openThankYouEmailModal: function () {
            if (this.isThankYouEmailModalActive === false) {
                this.isThankYouEmailModalActive = true;
            }
        },
        closeModal: function () {
            Object.keys(this.form).forEach(key => this.form[key] = null);
            this.isUserModalActive = false;
            this.isDeleteUserModalActive = false;
            this.isWelcomeEmailModalActive = false;
            this.isRemindEmailModalActive = false;
            this.isWeddingListEmailModalActive = false;
            this.isUrlReminderEmailModalActive = false;
            this.isCeremonyReminderEmailModalActive = false;
            this.isThankYouEmailModalActive = false;
        },
        addUser: function () {
            if (this.isFormValid) {
                let self = this;
                Api.addUser(self.form.username).then(function () {
                    self.closeModal();
                    self.loadUsers();
                });
            }
        },
        editUser: function () {
            if (this.isFormValid) {
                let self = this;
                Api.editUser(self.form.id, self.form.username, self.form.email).then(() => {
                    self.closeModal();
                    self.loadUsers();
                });
            }
        },
        deleteUser: function () {
            if (this.isFormValid) {
                this.$buefy.dialog.confirm({
                    message: 'Etes-vous sur de vouloir supprimer cet invité ?',
                    type: 'is-danger',
                    onConfirm: () => {
                        let self = this;
                        Api.deleteUser(self.form.id, self.form.delete, self.form.newUser).then(() => {
                            self.closeModal();
                            self.loadUsers();
                        });
                    },
                });
            }
        },
        sendWelcomeEmails: function () {
            let self = this;
            this.isSendingEmail = true;
            const users = this.checkedRows.map((row) => row.username);

            Api.welcomeEmails(users).then(() => {
                self.closeModal();
                self.checkedRows = [];
                self.isSendingEmail = false;
            });
        },
        sendRemindEmails: function () {
            let self = this;
            this.isSendingEmail = true;
            const users = this.checkedRows.map((row) => row.username);

            Api.remindEmails(users).then(() => {
                self.closeModal();
                self.checkedRows = [];
                self.isSendingEmail = false;
            });
        },
        sendWeddingListEmails: function () {
            let self = this;
            this.isSendingEmail = true;
            const users = this.checkedRows.map((row) => row.username);

            Api.weddingListEmails(users).then(() => {
                self.closeModal();
                self.checkedRows = [];
                self.isSendingEmail = false;
            });
        },
        sendUrlReminderEmails: function () {
            let self = this;
            this.isSendingEmail = true;
            const users = this.checkedRows.map((row) => row.username);

            Api.urlReminderEmails(users).then(() => {
                self.closeModal();
                self.checkedRows = [];
                self.isSendingEmail = false;
            });
        },
        sendCeremonyReminderEmails: function () {
            let self = this;
            this.isSendingEmail = true;
            const users = this.checkedRows.map((row) => row.username);

            Api.ceremonyReminderEmails(users).then(() => {
                self.closeModal();
                self.checkedRows = [];
                self.isSendingEmail = false;
            });
        },
        sendThankYouEmails: function () {
            let self = this;
            this.isSendingEmail = true;
            const users = this.checkedRows.map((row) => row.username);

            Api.thankYouEmails(users).then(() => {
                self.closeModal();
                self.checkedRows = [];
                self.isSendingEmail = false;
            });
        },
    },
}
</script>
<template>
    <b-navbar
        :mobile-burger="isLoggedIn"
    >
        <template #brand>
            <b-navbar-item
                tag="router-link"
                :to="{ name: 'Home' }"
                class="is-size-3 is-size-4-mobile is-brand"
                active-class="is-not-active"
            >
                Camille et Corentin
            </b-navbar-item>
        </template>
        <template v-if="isLoggedIn" #start>
            <b-navbar-item
                tag="router-link"
                :to="{ name: 'Home' }"
            >
                Home
            </b-navbar-item>
            <b-navbar-item
                tag="router-link"
                :to="{ name: 'Invite' }"
            >
                Invitations
            </b-navbar-item>
            <b-navbar-item
                tag="router-link"
                :to="{ name: 'Place' }"
            >
                Lieu et hébergements
            </b-navbar-item>
            <b-navbar-item
                tag="router-link"
                :to="{ name: 'WeddingList' }"
            >
                Liste de mariage
            </b-navbar-item>
            <b-navbar-item
                tag="router-link"
                :to="{ name: 'Contact' }"
            >
                Contact
            </b-navbar-item>
        </template>
        <template v-if="isLoggedIn" #end>
            <b-navbar-item
                tag="router-link"
                :to="{ name: 'Info' }"
            >
                Mes infos
            </b-navbar-item>
            <b-navbar-item
                v-if="isAdminLoggedIn"
                tag="router-link"
                :to="{ name: 'AdminDashboard' }"
            >
                Admin
            </b-navbar-item>
            <b-navbar-item
                tag="router-link"
                :to="{ name: 'Logout' }"
            >
                Se déconnecter
            </b-navbar-item>
        </template>
    </b-navbar>
</template>

<script>
import Api from '../../../js/api'

export default {
    name: 'Navbar',
    data: () => ({
        isLoggedIn: false,
        isAdminLoggedIn: false,
    }),
    mounted: function () {
        let token = localStorage.token;
        let self = this;
        if (token) {
            Api.verifyToken(token).then((response) => {
                if (response?.data?.status === "OK" && !response.data.mustChangePassword) {
                    self.isLoggedIn = true;
                    if (response?.data?.admin) {
                        self.isAdminLoggedIn = true;
                    }
                }
            });
        }
    },
}
</script>


<style scoped>
    .navbar {
        background: #678bafe0;
    }
    .navbar-item {
        color: #ffffff;
    }
    .is-brand {
        font-family: Pacifico;
    }
    .navbar-brand .navbar-item.is-active {
        color: #ffffff;
    }
    .navbar-brand .navbar-item:hover {
        color: #ffffff;
    }
    .navbar-menu .navbar-item.is-active {
        color: #262f64;
    }
    .navbar-menu .navbar-item:hover {
        color: #262f64;
        background: transparent;
    }
</style>

<style>
    .navbar-menu.is-active {
        background: #678bafe0;
    }
    .navbar-menu .navbar-item.is-active {
        background: #678bafe0;
    }
    .navbar-burger {
        color: #ffffff;
    }
</style>

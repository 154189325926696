<template>
    <div class='container'>
    </div>
</template>

<script>
export default {
    name: 'Logout',
    beforeRouteEnter: (to, from, next) => {
        localStorage.token = null;
        next({name: 'Login'});
    },
}
</script>